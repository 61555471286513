
.ClientRecord_left_title{
    /* height: 20px; */
    width: 100%;
    display: flex;
    /* align-items: center; */
    margin-top: 15px;

}
.ClientRecord_left_titleContent_unselected{
    font-size: 12px;
    color: black;
    margin: auto;
    margin-left: 0;
    /* font-style: italic; */

}
.ClientRecord_left_titleContent_selected{
    font-size: 12px;
    color: #EB4438;
    /* font-style: italic; */
}
.ClientRecord_left_titleflagTrigger{
    margin: auto;
    margin-left: 1%;
    margin-top: 0;
    /* font-style: italic; */
    
}
.ClientRecord_left_description{
    font-size: 12px;
    margin-top: 7px;
    color: #3F3F3F;
}
.ClientRecord_mid_text{
    font-size: 12px;
    margin-bottom: 5px;
}
.Record_right_content{
    height: 20px;
    width: 20px;
    margin: auto;
}
.ClientRecord_hidden{
    width: 90%;
    display: flex;
    /* border-style: solid; */
}
.ClientRecord_buttom_gap{
    width: 100%;
    height: 10px;
}