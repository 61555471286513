
.DropDownList_container{
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    border-radius: 5px;
}
.DropDownList_select{
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    border-width: 0px;
    outline: none;
}
.DropDownList_option{
    width: 100%;
    height: 28px;
    background-color: #F2F2F2;
    border: 0;
    background-color: #F2F2F2;
    background: transparent;

    display: flex;
    margin-right: 8%;
}