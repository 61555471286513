/* record hidden content */
.RecordContent_container{
    width: 100%;
    
    /* display: flex; */
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: space-between;
    /* height: 240px; */
    height: 3%;
}
.RecordContent_point{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    /* float: left; */

    /* border-style: solid; */
}
.RecordContent_point_left{
    width: 6px;
    height: 6px;
    margin-top: -2px;
}
.RecordContent_point_right{
    margin-left: 5px;
    font-size: 12px;
    color: #3F3F3F;
    line-height: 20px;
}