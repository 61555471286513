.Search_body{
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    overflow-y: scroll;
}
.Body_container{
    width: 85%;
    flex: 1;
    margin: 20px auto;
    margin-left: 7.5%;
    
    display: flex;
    
}
.Search_filter{
    width: 100%;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: normal;
}
.Search_filter_dropDownBox{
    width: 26%;
    min-width: 50px;
    height: 28px;
    margin-left: 5px;
    margin-right: 5px;
}
.Search_TitleContent{
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
}
.Search_ItemContent{
    font-size: 12px;
    font-weight: 500;
}
.Search_left{
    width: 15%;
    min-width: 210px;
    margin-left: 0px;
    margin-top: 0px;

    background-color: #fff;
    border-radius: 10px;
}
.Search_left_item{
    width: 80%;
    margin: 10px auto;
    display: flex;
}

.Search_mid{
    width: 67%;
    min-width: 500px;
    margin:0 20px;
    background-color: #fff;
    border-radius: 10px;

}
.Search_mid_title{
    width: 94%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
}
.Search_mid_TitleContent{
    /* flex-grow: 1; */
    width: 80%;
    height: 13px;
    margin:auto;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
}
.Search_mid_ActionContent{
    height: 13px;
    margin:auto;
    margin-right: 6%;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: bold;
}
.Search_mid_ButtonContent{
    height: 20px;
    width: 20px;
    margin: auto;
    margin-top: 0px;
    margin-right: 0px;
    /* margin-bottom: 5px; */

}
.Search_mid_BodyContent{
    width: 100%;
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    /* overflow-y: auto; */

}
.Search_mid_item{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.Body_mid_footer{
    width: 96%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.Body_mid_footer_left1{
    font-size: 12px;
    font-weight: 500;

}
.Body_mid_footer_left2{
    margin-left: 1%;
}
.Body_mid_footer_left2{
    height: 28px;

}
.Body_mid_footer_left3{
    flex-grow: 1;
    display: flex;
    justify-content: center;

    opacity: 0.5;
    font-size: 12px;
}
.Body_mid_footer_left4{
    font-size: 12px;
    margin-right: 15px;
}
.Body_mid_footer_left5{
    width: 30px;
    height: 30px;
    background-color: #F2F2F2;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;

    color: #808080;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Body_mid_footer_left6{
    width: 30px;
    height: 30px;
    background-color: #F2F2F2;
    border-radius: 5px;
    cursor: pointer;

    color: #808080;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Search_right{
    width: 15%;
    min-width: 210px;
    margin-right: 0px;
    margin-top: 0px;

    background-color: #fff;
    border-radius: 10px;
}
.Search_right_item{
    width: 80%;
    margin: 10px auto;
    display: flex;
}
.Search_right_itemCombo{
    width: 100%;
    flex-direction: column;
}