
.TextInput_container{
    width: 100%;
    height: 100%;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.TextInput_leftIcon{
    height: 100%;
    background-color: #FFF;
    display: flex;
    margin-left: 4%;
    margin-right: 3%;
}
.TextInput_right{
    flex-grow: 1;
    height: 100%;
    background-color: #FFF;
    display: flex;
    margin-right: 8%;
}
.TextInput_input{
    border:0px;
    outline: none;
    width: 100%;
}