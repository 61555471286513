
.Login_container{
    width: 100%;
    height: 100%;
    display: flex;
    
}
.Login_left{
    width: 40%;
    height: 100%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
}
.Login_left_container{
    width: 65.8%;
    margin: auto;
    display: flex;
    flex-direction: column;

}
.Login_left_container_text{
    font-size: 14px;
    color: #000;
    display:  flex;
    justify-content: center;

}
.Login_left_container_input{
    width: 100%;
    height: 60px;
    margin-top: 13px;
}
.Login_left_container_button{
    width: 100%;
    height: 62px;
    margin-top: 20px;
}
.Login_left_buttom{
    /* width: 65.8%; */
    margin:0 auto;
    margin-bottom: 4%;
    color: #EB4438;
    cursor: pointer;
}
.Login_right{
    width: 60%;
    height: 100%;
    background-color: #000;
    display: flex;
}
.Login_right_logo{
    width: 400px;
    height: 60px;
    margin: auto;
}
.MobileView_image{
    width: 80%;
    margin: auto;
}