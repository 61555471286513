
.Record_container{
    width: 94%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
.Record_showed{
    width: 100%;
    display: flex;
}
.Record_left{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-left: 0px;

    /* border-style: solid; */
}
.Record_left_gap{
    width: 100%;
    height: 15px;
}
.Record_mid{
    /* width: 80%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-right: 5%;



}
.Record_mid_button{
    width: 60px;
    height: 20px;
    margin-bottom: 5px;
    background-color: #000;
    border-radius: 10px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
}
.Record_right{
    /* width: 80%; */
    height: 100%;
    display: flex;
    margin: auto;
    margin-right: 0px;

    /* border-style: solid; */

}
.Record_right_content{
    height: 20px;
    width: 20px;
    margin: auto;
}
.Record_hidden{
    width: 100%;
    /* display: flex;
    flex-wrap: wrap; */
    display: flex;
}
.Record_buttom_gap{
    width: 100%;
    height: 10px;
}

