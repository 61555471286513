
.ClickOnButton_container{
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    /* text */
    font-size: 14;
    color: #FFF;
}

.Expander_container{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    /* border-style: solid; */
    border: 0.5px solid #29354C40;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

}
.Expander_UpArrow{
    width: 60%;
    height: 60%;
}
.Expander_DownArrow{
    width: 60%;
    height: 40%;
    /* margin: auto; */
}
.Flagged_container_unflagged{
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border-style: solid;
    border-color: #EB4438;
    background-color: #fff;
    border-width: 1px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #EB4438;
    font-size: 12px;
}
.Flagged_container_flagged{
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border-style: solid;
    border-color: #EB4438;
    background-color: #EB4438;
    border-width: 1px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
}