
.BlackLine_container{
    width: 100%;
    height: 2px;
    background-color: black;
}

.GreyLine_container{
    width: 100%;
    height: 1px;
    background-color: #F2F2F2;
}
