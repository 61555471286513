
/* ProfileDetailPopup */
.popup_container{
    background-color: #00000000;

}
.Profile_container{
    width: 600px;
    height: 250px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: #F2F2F2;
    border-radius: 10px;
}
.Profile_title{
    margin: auto;
    margin-top: 30px;

    font-size: 14px;
    font-weight: 500;
}
.Profile_input{
    width: 506px;
    height: 60px;
    margin: auto;
    margin-top: 15px;
}

.Profile_TextInput_container{
    width: 506px;
    height: 60px;
    margin: auto;
    margin-top: 10px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
}
.Profile_TextInput_leftIcon{
    height: 100%;
    background-color: #FFF;
    display: flex;
    margin-left: 4%;
    margin-right: 3%;
}
.Profile_TextInput_mid{
    flex-grow: 1;
    height: 100%;
    background-color: #FFF;
    display: flex;
    margin-right: 8%;
}
.Profile_TextInput_input{
    border:0px;
    outline: none;
    width: 100%;
}
.Profile_TextInput_rightIcon{
    width: 78px;
    height: 40px;
    background-color: #000;
    border-radius: 10px;
    margin-right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;

    cursor: pointer;
}
.Profile_gap{
    width: 100%;
    flex-grow: 1;
}
/* ProfilePopup */
.ProfilePopup_container{
    /* width: 127px;
    height: 43px; */

    margin-left: 20px;
    padding: 5px;
    background-color: #fff;
    border-radius: 10px;
}
.ProfilePopup_content{
    width: 80%;
    margin: 5px auto;
    cursor: pointer;

    font-size: 12px;
    display: inline-block;
}