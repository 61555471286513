.Search_header{
    height: 11.5%;
    min-height: 100px;
    width: 100%;
    background-color: #000;
    display: flex;
}
.Search_header_logo{
    /* width: 154px;
    min-width: 154px;
    height: 22.5px;
    min-height: 22.5px; */
    width: 161px;
    min-width: 161px;
    height: 26px;
    min-height: 26px;
    margin: auto 0 ;
    margin-left: 10%;
}
.Search_header_library{
    width: 26%;
    height: 50%;
    margin: auto 0 ;
    margin-left: 4%;
    display: flex;
    flex-direction: column;
}
.Search_header_trigger{
    margin-right: 0px;
    margin-bottom: -30px;
    background-color: #AAA;
}
.Search_header_keywords{
    width: 26%;
    height: 50%;
    margin: auto 0 ;
    margin-left: 1%;
}
.Search_header_flag{
    width: 150px;
    height: 50%;
    margin: auto 0 ;
    margin-left: 1%;

    background-color: #EB4438;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* text-align: center; */
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}
.Search_header_profile{
    width: 42px;
    height: 42px;
    margin:auto;
    margin-right: 8%;
}
/* LibrarySelector */
.HeaderLibrarySelector_container{
    background-color: #fff;
    border-radius: 10px;
    width: calc(100vw*0.261);
    /* width: 26%; */
    margin-bottom: -90%;
    display: flex;
    flex-direction: column;
    z-index: 999;
}
.HeaderLibrarySelector_item_FlaggedCases{
    width: 27%;
    margin: 5px auto;
    margin-left: 5%;
    background-color: #EB4438;
    border-radius: 10px;

    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}
.HeaderLibrarySelector_item{
    width: 90%;
    margin: 5px auto;
    font-size: 14px;
    color: #000;
    padding: 10px;
    cursor: pointer;
}


