
.FilterSelect_container{
    width: 94%;
    margin: 0 3%;
    display: flex;
    flex-direction: column;
}
.FilterSelect_showed{
    width: 100%;
    display: flex;
}

.FilterSelect_icon{
    width: 17px;
    height: 17px;
    margin:auto 0;
    cursor: pointer;

}
.FilterSelect_iconBlank{
    width: 17px;
    height: 17px;
    background-color: #F2F2F2;
    cursor: pointer;
}
.FilterSelect_name{
    margin:auto;
    margin-left: 10px;

    font-size: 12px;
    font-weight: 500;
}
.FilterSelect_hidden{
    width: 100%;
    height: 28px;
    /* margin: auto; */
    margin-top: 10px;
    
    /* border-style: solid; */
    display: flex;
}
.DateRangeSelect_datePickerArea{
    width: 48%;
}
.DateRangeSelect_words{
    margin: auto 5px ;

    font-size: 12px;
    
}
.FilterSelect_Gap{
    flex-grow: 1;
}
.RadioSelect_Button_unSelected{
    width: 48%;
    height: 28px;
    background-color: #F2F2F2;
    border-radius: 5px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    color: black;
}
.RadioSelect_Button_Selected{
    width: 48%;
    height: 28px;
    background-color: #000;
    border-radius: 5px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    color: #F2F2F2;
}
.DateLengthSelect_hidden{
    width: 100%;
    /* margin: auto; */
    margin-top: 10px;
    
    /* border-style: solid; */
    display: flex;
    flex-direction: column;
}
.DateLengthSelect_hiddenLines{
    width: 100%;
    height: 28px;
    margin-top: 5px;
    display: flex;
}
.DateLengthSelect_hiddenLines_Title{
    flex-grow: 1;
    margin:auto 0;
    font-size: 10px;
    font-weight: 500;
}
.DateLengthSelect_hiddenLines_Button{
    width: 36%;
    height: 100%;
    margin:auto;
    margin-right: 5px;
}
.DateLengthSelect_hiddenLines_Cancel{
    margin:auto;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #777777;
    cursor: pointer;
}

/* date picker component for DateLengthSelect*/
.Filter_DateInput_container{
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Filter_DateInput_input{
    width: 20%;
    /* height: 100%; */
    font-size: 10px;
    background-color: #F2F2F2;
    border:0px;
    outline: none;
}
.Filter_DateInput_text{
    margin-left: 0px;
    font-size: 10px;
    color: #000;
    opacity: 0.5;

}
.MultiChoiceSelect_hidden{
    width: 100%;
    /* height: 28px; */
    margin-top: 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
    
    /* border-style: solid; */
    display: flex;
    flex-direction: column;
}
.MultiChoiceSelect_name{
    margin:auto;
    margin-left: 10px;

    font-size: 10px;
}
.MultiChoiceSelect_iconBlank{
    width: 17px;
    height: 17px;
    background-color: #CECECE;
    /* opacity: 15%; */
    cursor: pointer;
}
.MultiChoiceSelect_showed{

    /* width: 100%; */
    margin: 7px auto;
    margin-left: 5%;
    display: flex;
}