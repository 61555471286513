
.LibrarySelect_container{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #000;
}
.LibrarySelect_content{
    width: 30%;
    margin: auto;
}
.LibrarySelect_logo{
    width: 80%;
    margin: 0 auto;
}
.LibrarySelect_input{
    width: 100%;
    height: 120px;
    margin: 50px auto;

    border-radius: 10px;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
}
.LibrarySelect_inputArea{
    width: 90%;
    min-height: 60px;
    margin: auto;
    margin-top: 30px;

    /* border-style: solid; */
}
.LibrarySelector_container{
    background-color: #fff;
    /* width: calc(100vw*0.261); */
    width: 90%;
    margin-bottom: 0px;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    z-index: 999;
}
.LibrarySelector_item{
    width: 90%;
    margin: 5px auto;
    font-size: 14px;
    color: #000;
    padding: 10px;
    cursor: pointer;
}
.LibrarySelector_item_FlaggedCases{
    width: 27%;
    margin: 5px auto;
    margin-left: 5%;
    background-color: #EB4438;
    border-radius: 10px;

    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 10px;
    cursor: pointer;
}