
/* ProfileDetailPopup */
.popup_container{
    background-color: #00000000;

}
.ForgetPassword_container{
    width: 600px;
    height: 284px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: #F2F2F2;
    border-radius: 10px;
}
.ForgetPassword_title{
    margin: auto;
    margin-top: 30px;

    font-size: 14px;
    font-weight: 500;
}
.ForgetPassword_content{
    margin:0 auto;
    margin-top: 10px;
    font-size: 14px;
}
.ForgetPassword_input{
    width: 80%;
    height: 60px;
    margin: 0 auto;
    margin-top: 16px;
}
.ForgetPassword_button{
    width: 80%;
    height: 62px;
    margin:0 auto;
    /* margin-bottom: 17.5%; */
    margin-top: 20px;

}
.ForgetPassword_success{
    width: 80%;
    height: 62px;
    margin:0 auto;
    /* margin-bottom: 17.5%; */
    margin-top: 20px;

    background-color: #404040;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ForgetPassword_gap{
    width: 100%;
    flex-grow: 1;
}