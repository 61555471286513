
.Logo_container{
    width: 100%;
    height: 100%;
    display: flex;

}
.ProfileSVG_container{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.Search_SVG_container{
    width: 100%;
    max-width: 25px;
    /* height: 100%; */
    margin: auto;
    cursor: pointer;
}
.Email_SVG_container{
    width: 60%;
    height: 60%;
    margin: auto;
    cursor: pointer;
}
.Password_SVG_container{
    width: 60%;
    height: 60%;
    margin: auto;
    cursor: pointer;
}
