
.Search_container{
    width: 100%;
    height: 100%;
    min-width: 1200px;
    /* min-height: 100vh; */
    /* border-width: 1; */
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
}

/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */
/* .Search_header{} */